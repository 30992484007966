import React, { useEffect, useState } from 'react';
import { excelToXML } from './components/handleConvertToXML';



function App() {
  const [authorizationCode, setAuthorizationCode] = useState(null);
  const [accessTokenData, setAccessTokenData] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const integratorKey = "52C9BE57-58AF-49F6-BDFA-76D68E98A895";
  const [exportApiResponse, setExportApiResponse] = useState(null);
  const [downloadApiResponse, setDownloadApiResponse] = useState(null);
  const [xmlFile, setXmlFile] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [xmlContent, setXmlContent] = useState('');
  const [uploadResponse, setUploadResponse] = useState(null);

  useEffect(() => {
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const code = getUrlParameter('code');
    setAuthorizationCode(code);
  }, []);

  const handleGetAccessToken = () => {
    fetch('https://login.cchaxcess.com/ps/auth/v1.0/core/connect/token', {
      method: 'POST',
      headers: {
        'Authorization': 'Basic OTY4ZDBhYzUtYTQzNy1mMmVjLWMwOTAtNzE2ZjQ2ZDFkNTkyOjdmMmY3YzE3LTIwNTQtMjE0NS05NjczLTlhYzcwZjUxOTEzNQ==',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `code=${authorizationCode}&redirect_uri=https://web-login-6l1.pages.dev&grant_type=authorization_code`
    })
    .then(response => response.json())
    .then(data => {
      setAccessTokenData(data);
      setRefreshToken(data.refresh_token);
    })
    .catch(error => console.error('Error:', error));
  };

  const handleRefreshToken = () => {
    fetch('https://login.cchaxcess.com/ps/auth/v1.0/core/connect/token', {
      method: 'POST',
      headers: {
        'Authorization': 'Basic OTY4ZDBhYzUtYTQzNy1mMmVjLWMwOTAtNzE2ZjQ2ZDFkNTkyOjdmMmY3YzE3LTIwNTQtMjE0NS05NjczLTlhYzcwZjUxOTEzNQ==',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `refresh_token=${refreshToken}&redirect_uri=https://web-login-6l1.pages.dev&grant_type=refresh_token`
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }
      return response.json();
    })
    .then(data => {
      setAccessTokenData(data);
      setRefreshToken(data.refresh_token); // Update the refresh token here
    })
    .catch(error => console.error('Error:', error));
  };

const handleExportTaxReturnDataXml = () => {
  const exportBody = {
    "ReturnId": ["2023P:1065:V1"],
    "ConfigurationXml": "<TaxDataExportOptions><ExportUnitsSelectionPreference>FilledWorksheetUnits</ExportUnitsSelectionPreference><DefaultPreferences><GenerateMeta>true</GenerateMeta><GenerateLookupItems>true</GenerateLookupItems><FieldValueExportSelection>AllFieldsAndIncludeData</FieldValueExportSelection><WorksheetGridExportMode>DetailMode</WorksheetGridExportMode><WhitepaperStatementExportMode>GenerateStatementStructureAndData</WhitepaperStatementExportMode></DefaultPreferences><ExportDiagnosticsMode>Suppress</ExportDiagnosticsMode><CalcReturnBeforeExport>false</CalcReturnBeforeExport><DefaultFieldIdentifierPreference>Description</DefaultFieldIdentifierPreference></TaxDataExportOptions>"
  };

  fetch('https://api.cchaxcess.com/taxservices/oiptax/api/v1/ReturnsExportBatch', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessTokenData.access_token}`,
      'Content-Type': 'application/json',
      'IntegratorKey': integratorKey,
      'Cache-Control': 'no-cache'
    },
    body: JSON.stringify(exportBody)
  })
  .then(response => response.json())
  .then(data => {
    setExportApiResponse(data);
    if (data.ExecutionID && data.FileResults && data.FileResults.length > 0 && data.FileResults[0].SubItemExecutionIDs.length > 0) {
      // Now passing 'output.zip' as the fileName to the download function
      handleDownloadTaxReturnDataXml(data.ExecutionID, data.FileResults[0].SubItemExecutionIDs[0], 'output.zip');
    } else {
      console.error('Invalid or missing data for download');
    }
  })
  .catch(error => console.error('Error:', error));
};


const handleDownloadTaxReturnDataXml = (batchGuid, batchItemGuid, fileName) => {
  // Checking for missing parameters and updating the state with the appropriate error message
  const missingParams = [];
  if (!batchGuid) missingParams.push("BatchGuid");
  if (!batchItemGuid) missingParams.push("BatchItemGuid");
  if (!fileName) missingParams.push("FileName");
  
  if (missingParams.length > 0) {
    const errorMessage = `Missing parameter(s): ${missingParams.join(", ")}`;
    console.error(errorMessage);
    setDownloadApiResponse(`Download failed: ${errorMessage}`);
    return;
  }

  const filterParams = `BatchGuid eq '${encodeURIComponent(batchGuid)}' and BatchItemGuid eq '${encodeURIComponent(batchItemGuid)}' and FileName eq '${encodeURIComponent(fileName)}'`;
  const url = `https://api.cchaxcess.com/taxservices/oiptax/api/v1/BatchOutputDownloadFile?$filter=${filterParams}`;

  console.log("Request URL:", url); // Debugging: Print the full request URL to verify its correctness

  fetch(url, {
    headers: {
      'Authorization': `Bearer ${accessTokenData.access_token}`,
      'IntegratorKey': integratorKey
    }
  })
  .then(response => {
    if (!response.ok) {
      return response.text().then(text => {
        console.error(`HTTP status ${response.status}: ${text}`);
        throw new Error(`Failed to download file with HTTP status ${response.status}: ${text}`);
      });
    }
    return response.blob();
  })
  .then(blob => {
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName; // Ensure the fileName includes a proper file extension
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(downloadUrl);
    setDownloadApiResponse(`File downloaded successfully: ${downloadUrl}`);
  })
  .catch(error => {
    console.error('Error:', error);
    setDownloadApiResponse(`Download failed: ${error.message}`);
  });
};



const handleFileChangeXML = (event) => {
    const file = event.target.files[0];
    if (file && (file.type.includes("xml") || file.name.split('.').pop().toLowerCase() === "xml")) {
        setXmlFile(file);
        setExcelFile(null);  // Clear any previously loaded Excel file
    } else {
        alert('Please upload a valid XML file.');
    }
};

const handleFileChangeExcel = (event) => {
    const file = event.target.files[0];
    if (file && (file.type.includes("excel") || file.type.includes("spreadsheetml") || ['xls', 'xlsx'].includes(file.name.split('.').pop().toLowerCase()))) {
        setExcelFile(file);
        setXmlFile(null);  // Clear any previously loaded XML file
    } else {
        alert('Please upload a valid Excel file.');
    }
};

  const handleConvertToXML = async () => {
    if (!excelFile) {
      alert('Please upload an Excel file first.');
      return;
    }
    const xml = await excelToXML(excelFile);  // Use the imported function
    setXmlContent(xml);
    downloadXML(xml);
  };

  const downloadXML = (xml) => {
    const blob = new Blob([xml], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'convertedFile.xml';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleSubmitXML = () => {
    if (!xmlFile) {
      alert('No XML file uploaded or invalid file type.');
      return;
    }

    const formData = new FormData();
    formData.append('file', xmlFile);
    formData.append('configurationXml', `<TaxDataImportOptions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                                                              xmlns:xsd="http://www.w3.org/2001/XMLSchema">
                                              <ImportMode>DeleteAndReplace</ImportMode>
                                              <CaseSensitiveMatching>false</CaseSensitiveMatching>
                                              <InvalidContentErrorHandling>RejectReturnOnAnyError</InvalidContentErrorHandling>
                                              <CalcReturnAfterImport>true</CalcReturnAfterImport>
                                              <UpdateExistingClientProfile>SkipUpdateForExistingClients</UpdateExistingClientProfile>
                                          </TaxDataImportOptions>`);

    fetch('https://api.cchaxcess.com/api/TaxService/v1.0/ImportTaxReturnDataXmlAsync', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessTokenData.access_token}`,
        'IntegratorKey': integratorKey
      },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      console.log('Upload Response:', data);
      setUploadResponse(data);
    })
    .catch(error => console.error('Error:', error));
  };

  return (
    <div>
      <h1>OAuth Example</h1>
      {!authorizationCode && (
        <button onClick={() => window.location.href = 'https://login.cchaxcess.com/ps/auth/v1.0/core/connect/authorize?response_type=code&client_id=968d0ac5-a437-f2ec-c090-716f46d1d592&redirect_uri=https://web-login-6l1.pages.dev&scope=CCHAxcess_ADSync_writeaccess CCHAxcess_data_writeaccess CCHAxcess_Profile IDInfo offline_access openid&acr_values={"AccountNumber":"153016"}'}>
          Login
        </button>
      )}
      {authorizationCode && !accessTokenData && (
        <div>
          <p><strong>Authorization Code:</strong> {authorizationCode}</p>
          <button onClick={handleGetAccessToken}>
            Get Token
          </button>
        </div>
      )}
      {accessTokenData && (
        <>
          <div>
            <p><strong>ID Token:</strong> {accessTokenData.id_token}</p>
            <p><strong>Access Token:</strong> {accessTokenData.access_token}</p>
            <p><strong>Expires In:</strong> {accessTokenData.expires_in}</p>
            <p><strong>Token Type:</strong> {accessTokenData.token_type}</p>
            <p><strong>Refresh Token:</strong> {accessTokenData.refresh_token}</p>
            <button onClick={handleRefreshToken}>
              Refresh Token
            </button>
          </div>
          <div>
            <strong>Access Token:</strong> <p>{accessTokenData.access_token}</p>
             <div>
            <h2>Convert Excel to XML</h2>
            <input type="file" onChange={handleFileChangeExcel} accept=".xlsx, .xls" />
            {excelFile && (
              <button onClick={handleConvertToXML} disabled={!excelFile}>
                Convert Excel to XML
              </button>
            )}
            {xmlContent && (
              <button onClick={() => downloadXML(xmlContent)}>
                Download XML
              </button>
            )}
          </div>

          <div>
            <h2>Submit XML</h2>
            <input type="file" onChange={handleFileChangeXML} accept=".xml" />
            {xmlFile && (
              <>
                <button onClick={handleSubmitXML} disabled={!xmlFile}>
                  Submit XML
                </button>
                {uploadResponse && (
                  <div>
                    <strong>Upload Response:</strong>
                    <pre>{JSON.stringify(uploadResponse, null, 2)}</pre>
                  </div>
                )}
              </>
            )}
          </div>

            <button onClick={handleExportTaxReturnDataXml}>
              Export Tax Return Data
            </button>
            {exportApiResponse && (
              <div>
                <p><strong>Export API Response:</strong></p>
                <pre>{JSON.stringify(exportApiResponse, null, 2)}</pre>
              </div>
            )}
            {downloadApiResponse && (
              <div>
                <p><strong>Download API Response:</strong></p>
                <pre>{downloadApiResponse}</pre>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default App;

