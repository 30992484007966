import * as XLSX from 'xlsx';
import { parse } from 'js2xmlparser';

export const excelToXML = async (file) => {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);

  // Read the 'Headers' sheet for dynamic configuration
  const headersSheet = workbook.Sheets['Headers'];
  const headersData = XLSX.utils.sheet_to_json(headersSheet, { header: 1 });
console.log('Headers Data:', headersData);  // Debugging output
  // Initialize the XML object
  const xmlObject = {
    "@": {
      "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
      "DataType": "Tax",
      "DataFormat": "Standard"
    },
    "TaxReturn": {
      "ReturnHeader": {
        "@": {}
      },
      "TaxPayerDetails": {
        "@": {}
      },
      "View": {
        "@": {
          "xsi:type": "Worksheet"
        },
        "Identifier": {
          "@": {
            "Hierarchy": "Federal\\General\\Basic Data",
            "ID": "47018"
          }
        },
        "Controls": {
          "Entity": {
            "@": { "ID": "1" }
          }
        },
        "WorkSheetSection": []
      }
    }
  };

  // Dynamically populate the ReturnHeader and TaxPayerDetails
  headersData.slice(1).forEach(item => {  // Skip the header row
    const [type, attribute, value] = item;
    if (type === 'ReturnHeader') {
      xmlObject.TaxReturn.ReturnHeader["@"][attribute] = value || '';  // Default empty string if value is undefined
    } else if (type === 'TaxPayerDetails') {
      xmlObject.TaxReturn.TaxPayerDetails["@"][attribute] = value || '';
    }
  });

  console.log('XML Object:', xmlObject);  // Debugging output
  
  // Assuming data for other parts of the XML is fetched from the 'Data' sheet
  const dataSheet = workbook.Sheets['Data'];
  const rows = XLSX.utils.sheet_to_json(dataSheet, { header: 1, blankrows: false, defval: '' });

  let sections = {};
  rows.forEach((row, index) => {
    if (index > 0 && row[0]) { // Skip header and completely empty rows
      const sectionID = row[0]; // Assuming section ID is in the first column
      if (!sections[sectionID]) {
        sections[sectionID] = {
          "@": {
            "ID": sectionID,
            "SectionNumber": row[1],
            "Name": row[2]
          },
          "FieldData": []
        };
        xmlObject.TaxReturn.View.WorkSheetSection.push(sections[sectionID]);
      }

      sections[sectionID].FieldData.push({
        "@": {
          "Location": row[3],
          "LocationType": "Description",
          "Value": row[5]
        },
        "FieldMeta": {
          "@": {
            "Type": row[4],
            "FieldName": row[6],
            "FieldID": row[7],
            "IsMandatory": row[8] === 'true',
            "IsReadOnly": row[9] === 'true',
            "Length": row[10],
            "UsesEnumDescription": row[11] === 'true'
          }
        }
      });
    }
  });

  // Convert JSON to XML
  const xml = parse("Payload", xmlObject);

  return xml;
};
